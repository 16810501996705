import React, {useState} from "react"
import {Link} from "gatsby"


const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(menuOpen ? false : true);
    }

    return (
      <div className={'header' + (menuOpen ? " menu-open" : "")}>
        <Link className="logo" to="/"><img className='logo' src={'/logo.svg'} /></Link>
        <ul onClick={toggleMenu} className={'menu' + (menuOpen ? " open" : "")}>            
            <li><Link to="/">Home</Link></li>
            <li><Link to="/ourscheme">Our scheme</Link></li>
            <li><Link to="/benefits">Benefits</Link></li>
            <li><Link to="/aboutyou">About you</Link></li>
            <li><Link to="/faqs">FAQs</Link></li>
            <li><Link to="/register">Register</Link></li>
        </ul>
        <button onClick={toggleMenu} class={"hamburger hamburger--spin" + (menuOpen ? " is-active" : "")} type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    )
}
  
export default Header