import * as React from "react"

import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import '../styles/styles.scss';
import Footer from "../components/Footer";



import Header from './Header'

const Layout = ({children}) => {
    return (
      <main>
          <Header />
          { children }
          <Footer />
      </main>
    )
}
  
export default Layout