import * as React from "react"
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
    return (
      <div className='footer'>
          <div className='brand-bar'>
              <img src='/logos/medical-logo.svg' />
              <img src='/logos/instantprint.svg' />
              <img src='/logos/routeprint.svg' />
              <img src='/logos/kbp.svg' />

          </div>
          <div className='footer-top'>
              <div>
                  <img src='/logos/logo-white.svg' />
              </div>
              <ul>
                  <li><a href="https://www.bluetreegroup.co.uk/wp-content/uploads/2021/07/Bluetree-Labour-standards-policy-March-2021.pdf">Labour Standards Policy</a></li>
                  <li><a href="https://www.bluetreegroup.co.uk/wp-content/uploads/2021/07/Bluetree-Medical-Modern-Slavery-Statement-March-2021.pdf">Modern Slavery Statement</a></li>
                  <li><a href="https://www.bluetreegroup.co.uk/wp-content/uploads/2019/07/Gender-Pay-Gap-Report.pdf">Gender Pay Report</a></li>
                  <li><a href="https://www.bluetreegroup.co.uk/wp-content/uploads/2019/07/Bluetree-Group-Recruitment-Policy-Dec18.pdf">Recruitment Policy</a></li>
                  <li><a href="https://www.bluetreegroup.co.uk/privacy-policy/">Privacy Policy</a></li>
              </ul>
          </div>
          <div className='footer-bottom'>
              <div>
                  <p>&copy; 2023 - Developed by Bluetree</p>
              </div>
              <div className='social-links'>
                  <p>Get social with us!</p>
                  <a href="https://www.facebook.com/instantprintuk"><img src='/logos/facebook.svg'></img></a>
                  <a href="https://www.linkedin.com/company/bluetreegroup/"><img src='/logos/linkedin.svg'></img></a>
                  <a href="https://twitter.com/instantprintuk/"><img src='/logos/twitter.svg'></img></a>
                  <a href="https://www.instagram.com/instantprintuk"><img src='/logos/instagram.svg'></img></a>
              </div>
          </div>          

      </div>
    )
}
  
export default Header